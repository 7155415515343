import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { getH } from './ddos.js'
// let URL = 'https://devapi.sportsx9.com/api/v1';
let URL = 'https://api.sportsx9.com/api/v1';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // URL = 'http://localhost:5000/api/v1';
  URL = 'https://api.sportsx9.com/api/v1';
  // URL = 'https://devapi.sportsx9.com/api/v1';
}

const Axios = () => {
  const AxiosInstance = axios.create({
    baseURL: URL,
    timeout: 15000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-auth-token': JSON.parse(localStorage.getItem('@access:token')),
    },
  });
  AxiosInstance.interceptors.request.use(async function (config) {
    const token = JSON.parse(localStorage.getItem('@access:token'));
    if (token || token !== null) {
      const decodedToken = jwtDecode(token);
      const currentDate = Date.now() / 1000;
      const timeUntilExpiration = decodedToken.exp - currentDate;
      if (timeUntilExpiration < 3000) {
        try {
          const cryptoHeaders1 = getH(URL);
          // const response = await axios.post('https://devapi.sportsx9.com/api/v1/userAccount/refreshToken', {},
          const response = await axios.post('https://api.sportsx9.com/api/v1/userAccount/refreshToken', {},
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-auth-token': JSON.parse(localStorage.getItem('@access:token')),
                'X-Hack-Token': cryptoHeaders1['X-Hack-Token'],
                'X-Expire': cryptoHeaders1['X-Expire'],
                'X-Path': cryptoHeaders1['X-Path'],
                'X-Request-Id': cryptoHeaders1['X-Request-Id'],
                'device-id': cryptoHeaders1['device-id']
              },
            });
          const newAccessToken = response.data.access_token;
          AxiosInstance.defaults.headers['x-auth-token'] = newAccessToken;
          localStorage.setItem('@access:token', JSON.stringify(newAccessToken));
        } catch (error) {
          // localStorage.removeItem("loginUserInfo");
          // localStorage.clear();
          // window.location.href = "https://sportsx9.com/";
          // return Promise.reject(new Error('Token refresh failed'));
        }
      }
    }

    config.headers['x-auth-token'] = JSON.parse(localStorage.getItem('@access:token'));
    const cryptoHeaders = getH(URL);
    config.headers['X-Hack-Token'] = cryptoHeaders['X-Hack-Token'];
    config.headers['X-Expire'] = cryptoHeaders['X-Expire'];
    config.headers['X-Path'] = cryptoHeaders['X-Path'];
    config.headers['X-Request-Id'] = cryptoHeaders['X-Request-Id'];
    config.headers['device-id'] = cryptoHeaders['device-id'];
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

  AxiosInstance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("loginUserInfo");
      localStorage.clear();
      console.log('Status user logout')
      window.location.href = "https://sportsx9.com/";
      //window.location.reload();
    }

    return Promise.reject(error);
  });

  return AxiosInstance;
};

export default Axios();
