import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const CommonNavbar = (props) => {
    const [isMarketType, setIsMarketType] = useState('');
    const history = useHistory();
    const location = useLocation();

    const isMounted = React.useRef(true);

    useEffect(() => {

        isMounted.current = true;

        const path = location.pathname;
        if (path.includes('/wallet/Deposit')) {
            setIsMarketType('deposit');
        } else if (path.includes('/wallet/Withdrawl')) {
            setIsMarketType('withdraw');
        } else if (path.includes('/profile')) {
            setIsMarketType('profile');
        } else if (path.includes('/accountStatement')) {
            setIsMarketType('accountStatement');    
        } else if (path.includes('/stackSetting')) {
            setIsMarketType('stackSetting');
        } else {
            setIsMarketType('');
        }


        return () => {
            isMounted.current = false;
        };
    }, [location.pathname]);

    const handleRedirect = (marketType, path) => {
        setIsMarketType(marketType);
        history.push(path);
    };

    return (
        <div className="row">
            <div className="col-lg-12 col-sm-12 mb-2 btn-combs">
                <div className="market-btn">
                    <button
                        className={`market-button ${isMarketType === 'profile' ? 'selected' : ''}`}
                        onClick={() => handleRedirect('profile', '/profile')}
                    >
                        Profile {isMarketType === 'profile' ? <img src="/assets/images/d-arrow.png" alt="arrow" /> : ''}
                    </button>
                    {/* <button
                        className={`market-button ml-2 ${isMarketType === 'deposit' ? 'selected' : ''}`}
                        onClick={() => handleRedirect('deposit', '/wallet/Deposit/upto2k')}
                    >
                        Deposit {isMarketType === 'deposit' ? <img src="/assets/images/d-arrow.png" alt="arrow" /> : ''}
                    </button> */}
                    {/* <button
                        className={`market-button ml-2 ${isMarketType === 'withdraw' ? 'selected' : ''}`}
                        onClick={() => handleRedirect('withdraw', '/wallet/Withdrawl')}
                    >
                        Withdraw {isMarketType === 'withdraw' ? <img src="/assets/images/d-arrow.png" alt="arrow" /> : ''}
                    </button> */}
                    <button
                        className={`market-button ml-2 ${isMarketType === 'accountStatement' ? 'selected' : ''}`}
                        onClick={() => handleRedirect('accountStatement', '/accountStatement')}
                    >
                        Account Statement {isMarketType === 'accountStatement' ? <img src="/assets/images/d-arrow.png" alt="arrow" /> : ''}
                    </button>
                    {/* <button
                        className={`market-button ml-2 ${isMarketType === 'report' ? 'selected' : ''}`}
                        onClick={() => handleRedirect('report', '/report')}
                    >
                        Deposit-Withdraw Report {isMarketType === 'report' ? <img src="/assets/images/d-arrow.png" alt="arrow" /> : ''}
                    </button> */}
                    {/* <button
                        className={`market-button ml-2 ${isMarketType === 'report' ? 'selected' : ''}`}
                        onClick={() => handleRedirect('report', '/report')}
                    >
                        P/L Report {isMarketType === 'report' ? <img src="/assets/images/d-arrow.png" alt="arrow" /> : ''}
                    </button> */}
                    <button
                        className={`market-button ml-2 ${isMarketType === 'stackSetting' ? 'selected' : ''}`}
                        onClick={() => handleRedirect('stackSetting', '/stackSetting')}
                    >
                        Chip Setting {isMarketType === 'stackSetting' ? <img src="/assets/images/d-arrow.png" alt="arrow" /> : ''}
                    </button>
                </div>

            </div>
        </div>
    );
};

export default CommonNavbar;
